import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
  position: 'relative',
  transition:
    'background 0.3s ease 0s, border 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s',
  backgroundPosition: 'right center',
  backgroundImage: 'url("/images/prancheta.png")',
  backgroundRepeat: 'no-repeat',
  [theme.breakpoints.down('md')]: {
    backgroundSize: '319px auto',
  },
  [theme.breakpoints.down('sm')]: {
    backgroundPosition: 'right bottom',
    backgroundSize: '185px',
  },
}));

const ClipButton = styled('button')(({ theme }) => ({
  cursor: 'pointer',
  appearance: 'button',
  transition: 'all 0.3s ease 0s',
  display: 'inline-block',
  lineHeight: 1,
  fontSize: '15px',
  color: 'rgb(255, 255, 255)',
  fill: 'rgb(255, 255, 255)',
  textAlign: 'center',
  textDecoration: 'none',
  boxShadow: 'none',
  width: 'auto',
  fontWeight: 500,
  borderRadius: '100px',
  padding: '16px 30px',
  backgroundColor: 'rgb(25, 186, 187)',
  border: 'none',
}));

const DefaultLabels = {
  title: 'Ops...',
  description: 'Não temos nada por aqui.',
  buttonLabel: 'Busque clipes',
};

type EmptyScreenProps = {
  labels?: {
    title?: string;
    description?: string;
    buttonLabel?: string;
  };
  goToClips: () => void;
};

const EmptyScreen = (props: EmptyScreenProps) => {
  const { labels = DefaultLabels, goToClips } = props;
  return (
    <Root>
      <div
        style={{
          display: 'flex',
          marginRight: 'auto',
          marginLeft: 'auto',
          position: 'relative',
          MozBoxAlign: 'center',
          alignItems: 'center',
          maxWidth: '835px',
          minHeight: '70vh',
        }}
      >
        <div
          style={{
            minHeight: '1px',
            position: 'relative',
            display: 'flex',
            width: '100%',
          }}
        >
          <div
            style={{
              position: 'relative',
              width: '100%',
              flexWrap: 'wrap',
              alignContent: 'flex-start',
              display: 'flex',
            }}
          >
            <div
              style={{
                position: 'relative',
                width: '100%',
                marginBottom: '10px',
                textAlign: 'center',
              }}
            >
              <div>
                <h2
                  style={{
                    padding: '0px',
                    margin: '0px',
                    marginTop: '0px',
                    marginBottom: '0px',
                    lineHeight: 1,
                    color: 'rgb(75, 75, 75)',
                    fontSize: '38px',
                    fontWeight: 400,
                  }}
                >
                  {labels?.title}
                </h2>
              </div>
            </div>
            <div
              style={{
                position: 'relative',
                width: '100%',
                marginBottom: '10px',
                textAlign: 'center',
              }}
            >
              <div
                style={{
                  transition:
                    'background 0.3s ease 0s, border 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s',
                }}
              >
                <h2
                  style={{
                    padding: '0px',
                    margin: '0px',
                    marginTop: '0px',
                    marginBottom: '0px',
                    lineHeight: 1,
                    color: 'rgb(81, 81, 81)',
                    fontSize: '22px',
                    fontWeight: 400,
                  }}
                >
                  {labels?.description}
                </h2>
              </div>
            </div>
            <div
              style={{
                textAlign: 'center',
                position: 'relative',
                width: '100%',
              }}
            >
              <div>
                <div style={{ boxSizing: 'border-box' }}>
                  <ClipButton onClick={goToClips}>
                    <span
                      style={{
                        display: 'flex',
                        MozBoxPack: 'center',
                        justifyContent: 'center',
                        textDecoration: 'inherit',
                      }}
                    >
                      <span
                        style={{
                          MozBoxFlex: '1',
                          flexGrow: 1,
                          MozBoxOrdinalGroup: '11',
                          order: 10,
                          display: 'inline-block',
                          textDecoration: 'inherit',
                        }}
                      >
                        {labels?.buttonLabel}
                      </span>
                    </span>
                  </ClipButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Root>
  );
};

export default EmptyScreen;
