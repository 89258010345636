import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { systemConfig } from '@/features/system/system.config';
import EmptyScreen from '@bamboo/core-lib/components/Display/EmptyScreen';
import AppFooter from '@/components/layout/AppFooter';
import { AppPaperbase } from '@/components/layout';
import type { FC } from 'react';

type Props = {
  title?: string;
  children?: never;
};

export const NotFoundPage: FC<Props> = (props) => {
  const { t } = useTranslation(systemConfig.i18nNamespaces);
  const router = useRouter();

  const title = props.title ?? t('system:notFound.title');
  const goToClips = React.useCallback(() => {
    router.push('/search');
  }, [router]);

  return (
    <AppPaperbase seoProps={{ title }}>
      <EmptyScreen
        goToClips={goToClips}
        labels={{
          title: t('system:page.title'),
          description: t('system:page.description'),
          buttonLabel: t('system:page.buttonLabel'),
        }}
      />
      <AppFooter
        labels={{
          helpTitle: t('common:footer.helpTitle'),
          helpDescription: t('common:footer.helpDescription'),
          helpButtonLabel: t('common:footer.helpButtonLabel'),
          infoTitle: t('common:footer.infoTitle'),
          infoProjects: t('common:footer.infoProjects'),
          infoAccount: t('common:footer.infoAccount'),
          infoFAQ: t('common:footer.infoFAQ'),
          infoHelp: t('common:footer.infoHelp'),
          aboutTitle: t('common:footer.aboutTitle'),
          aboutWhoWeAre: t('common:footer.aboutWhoWeAre'),
          aboutJobs: t('common:footer.aboutJobs'),
          licensingTitle: t('common:footer.licensingTitle'),
          licensingPlaylist: t('common:footer.licensingPlaylist'),
          licensingSearch: t('common:footer.licensingSearch'),
          instagram: t('common:footer.instagram'),
        }}
      />
    </AppPaperbase>
  );
};
